@import "~antd/dist/antd.css";

/* .App {
  display: flex;
  flex-direction: column;
  align-items: center;
} */

.App-logo {
  height: 40px;
}

.App-header {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.ant-layout-header {
  padding: 5%;
}

.App-content {
  /* font-size: calc(10px + 2vmin); */
  font-size: 16px;
  color: white;
  padding: 2em 1em;
}

.App-link {
  color: #09d3ac;
}

.ant-form {
  display: flex;
  flex-wrap: wrap;
  /* max-width: 350px; */
  margin: auto;
}

.form-elements {
  width: 50%;
}

.form-button {
  margin: auto 12px;
  width: 100%;
}

.ant-row {
  justify-content: space-between;
}

@media (max-width: 575px) {
  .ant-form {
    min-width: inherit;
  }
  .form-elements {
    width: 100%;
  }
}

.ant-form-vertical .ant-form-item {
  background-color: #f6f6f6;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 0px 12px 24px;
}

.ant-form label {
  font-size: 16px;
}

/* .form-switch {
  display: inline-flex;
  flex-direction: row !important;
  width: 100%;
}

.form-switch .ant-form-item-label {
  padding: 0 20px 0 10px;
}

.form-switch .ant-form-item-control {
  margin: auto;
} */

.form .ant-input-number {
  width: 100%;
  font-size: 16px;
}

.ant-input-number:hover .ant-input-number-handler-wrap {
  opacity: 0;
}

@media (max-width: 575px) {
  .ant-form-item-control-wrapper {
    text-align: center;
    justify-content: center;
  }
}

.ant-table {
  font-size: 16px;
}

.editable-cell {
  position: absolute;
}

.editable-cell-value-wrap {
  padding: 5px 3px;
  /* padding: 5px 12px; */
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  /* box-shadow: 0 0 1em #d9d9d9; */
  padding: 4px 2px;
  /* padding: 4px 11px; */
}

.strong-data {
  font-weight: 700;
}

.tableCashCSS .table {
  border-collapse: collapse;
}
.tableCashCSS thead > tr > th {
  background-color: #91d5ff;
  /* color: rgba(0, 0, 0, 0.65); */
}
.tableCashCSS tfoot {
  background-color: #e6f7ff;
}

.tableSuccessCSS .table {
  border-collapse: collapse;
}
.tableSuccessCSS thead > tr > th {
  background-color: #87e8de;
  /* color: rgba(0, 0, 0, 0.65); */
}
.tableSuccessCSS tfoot {
  background-color: #e6fffb;
}
